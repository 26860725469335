import React from "react";
import StripesBtn from "../components/button/StripesBtn/StripesBtn";
import Snake from "../asset/background/snake.jpg";
import Snake2 from "../asset/background/SnakeDesktop.jpg";

const Announcement = () => {
  return (
    <section id="announcements" className="announcement h-screen flex justify-center my-10">
      <div className="wrapper w-full lg:w-[50%] flex flex-col items-center gap-5 ">
        <StripesBtn isButton={false}>Announcement</StripesBtn>
        <div className="announce-content relative w-full flex flex-col gap-10">
          <div className="announce-wrapper flex flex-col items-center gap-5 p-2 text-white">
            <img src={Snake} alt="" width={"100%"} height={"auto"} className="block lg:hidden"/>
            <img src={Snake2} alt="" width={"100%"} height={"100%"} className="hidden lg:block" />

          </div>
        </div>
      </div>
    </section>
  );
};

export default Announcement;
