import React from "react";
import "./Home.css";
import "../components/styles/StripesBtn.css";
import Partnership from "../pagesComponents/Partnership";
import Community from "../pagesComponents/Community";
import Game from "../pagesComponents/Game";
import About from "../pagesComponents/About";
import Featured from "../pagesComponents/Featured";
import Hero from "../pagesComponents/Hero";
import GeneralInfo from "../pagesComponents/GeneralInfo";
import Announcement from "../pagesComponents/Announcement";


const Home = () => {
  

  return (
    <div className="bg-[#080c2b] pb-10">
      <Hero/>
      <Featured/>
      <Announcement/>
      <About/>
      <Game/>
      <Community/>
      <GeneralInfo/>
      <Partnership/>
    </div>
  );
};

export default Home;
